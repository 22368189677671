import { createGlobalState } from '@vueuse/core';
import type { Invoice } from '../../types';

const useStore = createGlobalState(() => {
	const invoice = ref<Invoices>([]);

	return {
		invoice,
	};
});

export const usePartnersInvoice = () => {
	const { invoice } = useStore();
	return computed(() => invoice.value);
};

export const savePartnersInvoices = (invoice: Invoice) => {
	useStore().invoice.value = invoice;
};
