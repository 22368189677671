<script setup lang="ts">
import { BaseBadge } from '@legacy-studio/library';
import TariffsIcon from './Icon.vue';
import type { UserAccountType } from '~/src/shared/lib';

const props = defineProps<{
	type: UserAccountType;
}>();

const titleMap: Record<UserAccountType, string> = {
	free: 'Free',
	premium: 'Premium',
	standart: 'Standart',
	gold: 'Gold',
	vip: 'VIP',
};

const title = computed(() => titleMap[props.type]);
</script>

<template>
	<BaseBadge color="gray">
		<TariffsIcon
			class="text-[14px]"
			:type="type"
		/>

		<span class="text-white">
			{{ title }}
		</span>
	</BaseBadge>
</template>
