import { createGlobalState } from '@vueuse/core';
import type { AssetsList } from '../../types';

const useStore = createGlobalState(() => {
	const list = ref<AssetsList | null>(null);

	return {
		list,
	};
});

export const useAssets = () => {
	const { list } = useStore();
	return computed(() => list.value || []);
};

export const saveAssetsList = (assets: AssetsList) => {
	const { list } = useStore();
	list.value = assets;
};

export const readAssetsList = () => {
	const { list } = useStore();
	return list.value;
};
