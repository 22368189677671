<script setup lang="ts">
import { BaseButton } from '@legacy-studio/library';
import type { TariffItem, TariffType } from '../types';
import { TariffsBadge } from '.';
import { useUserAccountType } from '@/src/features/user';
import { UiDarkBBButton } from '~/src/shared/ui';

const props = defineProps<{
	tariff: TariffItem;
}>();

defineEmits<{
	(event: 'increase', value: TariffType): void;
}>();
type InfoItem = {
	label: string;
	content: string;
};
const current = useUserAccountType();
const codes = ['free', 'standart', 'gold', 'premium', 'vip'];

const currentCodeIndex = computed(() => codes.indexOf(current.value));
const tariffCodeIndex = computed(() => codes.indexOf(props.tariff.code));

const isCurrentOrBefore = computed(() => {
	return tariffCodeIndex.value <= currentCodeIndex.value;
});

const createInfoItem = (label: string, content: string): InfoItem => ({ label, content });

const formatValue = (value: number, suffix: string = '') => value ? `$${value.toLocaleString()}<span class="text-gray-500">${suffix}</span>` : '';
const formatDays = (days: number) => days === 1 ? 'дня' : 'дней';

const infoList = computed<InfoItem[]>(() => {
	const { dayLimit, weekLimit, withdrawalProcessingLimit, incomePercent, demoIncrease, name } = props.tariff;

	return [
		dayLimit && weekLimit && createInfoItem(
			'Лимит на вывод средств:',
        `<span class="text-white">${formatValue(dayLimit, '-день')}</span><span class="text-gray-500">,</span>
       <span class="text-white">${formatValue(weekLimit, '-неделя')}</span>`,
		),
		withdrawalProcessingLimit && createInfoItem(
			'Обработка заявок на вывод:',
        `<span class="text-white">до ${withdrawalProcessingLimit} ${formatDays(withdrawalProcessingLimit)}</span>`,
		),
		incomePercent && createInfoItem(
			'Доходность по активам:',
        `<span class="text-green-400">+${incomePercent}%</span><span class="text-white"> к доходности</span>`,
		),
		demoIncrease && createInfoItem(
			'Средства на демо-счете:',
        `<span class="text-green-400">${formatValue(demoIncrease)}</span>`,
		),
		createInfoItem(
			'Тип турниров:',
        `<span class="text-white">${name}</span>`,
		),
	].filter(Boolean) as InfoItem[];
});

const onClickIncrease = () => {
	Partners('increase', props.tariff.code);
};
</script>

<template>
	<div
		class="flex h-full w-full max-w-[448px] flex-col rounded-lg border border-gray-600 bg-gray-700 p-8 shadow-2xl xl:px-4"
	>
		<TariffsBadge
			:type="tariff.code"
			class="mx-auto mb-4"
		/>

		<p class="mb-6 text-center text-xl font-extrabold text-white">
			$ {{ props.tariff.sum }}
		</p>
		<BaseButton
			v-if="isCurrentOrBefore"
			color="alternative-dark"
			disabled
			:button-text="`Уровень достигнут`"
			class="mb-6 md:mb-[26px]"
			outline
		/>

		<UiDarkBBButton
			v-else
			:button-text="`Повысить`"
			class="mb-6 md:mb-[26px]"
			@click="onClickIncrease"
		/>

		<div class="flex flex-col gap-4 text-left">
			<div
				v-for="(info, index) in infoList"
				:key="index"
				class="flex flex-col gap-[2px] text-sm font-normal"
			>
				<span class="text-gray-500 py-[2px]">{{ info.label }}</span>
				<span
					class="font-medium"
					v-html="info.content"
				/>
			</div>
		</div>
	</div>
</template>
