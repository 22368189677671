import { createGlobalState } from '@vueuse/core';
import type { TraderProfile } from '~/src/features/profile/infrastructure/api/getTraderProfile';
import type { TraderVerification } from '~/src/features/profile/infrastructure/api/addTraderVerification';
import type { PartnerProfile } from '~/src/features/profile/infrastructure/api/getPartnerProfile';

const useStore = createGlobalState(() => {
	const profile = ref<TraderProfile | PartnerProfile | null>(null);
	const isModalOpen = ref(false);
	const isBlockAccountModalOpen = ref(false);
	const isEmailEdit = ref(false);
	const isChangePasswordEdit = ref(false);
	const isLoginEdit = ref(false);
	const isPersonalEdit = ref(false);
	const isPersonalityEdit = ref(false);
	const isTrafficEdit = ref(false);
	const isAddPaymentEdit = ref(false);

	return {
		profile,
		isModalOpen,
		isBlockAccountModalOpen,
		isEmailEdit,
		isChangePasswordEdit,
		isLoginEdit,
		isPersonalEdit,
		isPersonalityEdit,
		isTrafficEdit,
		isAddPaymentEdit,
	};
});

export const saveProfile = (user: TraderProfile | PartnerProfile | null) => {
	useStore().profile.value = user;
};

export const saveTraderProfileVerification = (verification: TraderVerification) => {
	(useStore().profile.value as TraderProfile).verification = verification;
};

export const useIsProfileModalOpen = () => {
	const { isModalOpen } = useStore();
	return computed(() => isModalOpen.value);
};

export const saveIsModalOpen = (isOpen: boolean) =>
	(useStore().isModalOpen.value = isOpen);

export const useIsBlockAccountModalOpen = () => {
	const { isBlockAccountModalOpen } = useStore();
	return computed(() => isBlockAccountModalOpen.value);
};

export const saveIsBlockAccountModalOpen = (isOpen: boolean) =>
	(useStore().isBlockAccountModalOpen.value = isOpen);

export const useIsEmailEdit = () => {
	const { isEmailEdit } = useStore();
	return computed(() => isEmailEdit.value);
};

export const saveIsEmailEdit = (isEdit: boolean) =>
	(useStore().isEmailEdit.value = isEdit);

export const useIsChangePasswordEdit = () => {
	const { isChangePasswordEdit } = useStore();
	return computed(() => isChangePasswordEdit.value);
};

export const saveIsChangePasswordEdit = (isEdit: boolean) =>
	(useStore().isChangePasswordEdit.value = isEdit);

export const useIsLoginEdit = () => {
	const { isLoginEdit } = useStore();
	return computed(() => isLoginEdit.value);
};

export const saveIsLoginEdit = (isEdit: boolean) =>
	(useStore().isLoginEdit.value = isEdit);

export const readProfile = () => {
	const { profile } = useStore();
	return profile.value;
};

export const useProfile = () => {
	const { profile } = useStore();
	return computed(() => profile.value);
};

export const useIsPersonalEdit = () => {
	const { isPersonalEdit } = useStore();
	return computed(() => isPersonalEdit.value);
};

export const saveIsPersonalEdit = (isEdit: boolean) =>
	(useStore().isPersonalEdit.value = isEdit);

export const useIsPersonalityEdit = () => {
	const { isPersonalityEdit } = useStore();
	return computed(() => isPersonalityEdit.value);
};

export const saveIsPersonalityEdit = (isEdit: boolean) =>
	(useStore().isPersonalityEdit.value = isEdit);

export const useIsTrafficEdit = () => {
	const { isTrafficEdit } = useStore();
	return computed(() => isTrafficEdit.value);
};

export const saveIsTrafficEdit = (isEdit: boolean) =>
	(useStore().isTrafficEdit.value = isEdit);

export const useIsAddPaymentEdit = () => {
	const { isAddPaymentEdit } = useStore();
	return computed(() => isAddPaymentEdit.value);
};

export const saveIsAddPaymentEdit = (isAddEdit: boolean) =>
	(useStore().isAddPaymentEdit.value = isAddEdit);
