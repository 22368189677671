<script setup lang="ts">
import {
	AppModals,
	useAppAsyncData,
} from '@/src/features/app';

useAppAsyncData();
</script>

<template>
	<NuxtLayout>
		<NuxtPage />
	</NuxtLayout>

	<AppModals />
</template>
