import { useErrorHandler } from '~/src/services/error';

export default defineNuxtPlugin((nuxtApp) => {
	const handleError = useErrorHandler();

	nuxtApp.vueApp.config.errorHandler = (error, _instance, info) => {
		console.error('[ErrorHandler]', error, info);
		handleError(error);
	};
});
