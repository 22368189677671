import { createGlobalState } from '@vueuse/core';
import type { TariffItems, TariffItemsPayment } from '../../types';

const useStore = createGlobalState(() => {
	const tariffs = ref<TariffItems | null>(null);
	const isModalOpen = ref(false);

	return {
		tariffs,
		isModalOpen,
	};
});

export const saveTariffs = (tariffs: TariffItems) => {
	useStore().tariffs.value = tariffs;
};

export const useTariffs = () => {
	const { tariffs } = useStore();
	return computed(() => tariffs.value || []);
};

export const saveIsTariffsModalOpen = (isOpen: boolean) => {
	useStore().isModalOpen.value = isOpen;
};

export const useIsTariffsModalOpen = () => {
	const { isModalOpen } = useStore();
	return computed(() => isModalOpen.value);
};

export const usePaymentTariffs = () => {
	const { tariffs } = useStore();

	return computed(() => {
		return (
			(tariffs.value?.filter(item => !!item.sum) as TariffItemsPayment) || []
		);
	});
};
