import revive_payload_client_4sVQNw7RlN from "/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_W86eSGFqyZ from "/frontend/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _01_auth_66fnPBoWcQ from "/frontend/plugins/01.auth.ts";
import error_handler_kEP5FliEXj from "/frontend/plugins/error-handler.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_W86eSGFqyZ,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  _01_auth_66fnPBoWcQ,
  error_handler_kEP5FliEXj
]