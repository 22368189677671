import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { UnwrapRefSimple } from '@vue/reactivity';
import type { ExpirationTime, ExpirationTimeList } from '../../types';

const SHORT_TIMES_LENGTH = 5;
const SHORT_INTERVAL = 1;

const getCurrent = () => {
	let initial = dayjs(new Date()).set('seconds', 0).set('milliseconds', 0).utc();

	if (isNeedNextMinute()) {
		initial = initial.add(1, 'minute');
	}

	return initial;
};

export const isNeedNextMinute = () => dayjs(new Date()).get('seconds') >= 30;

export const getInitialExpirationTime = (): ExpirationTime => {
	const initial = getCurrent();
	let next = initial.add(SHORT_INTERVAL, 'minute');

	if (isNeedNextMinute()) {
		next = next.add(1, 'minute');
	}

	return {
		value: next.toDate(),
		stringified: next.format('HH:mm'),
	};
};

export const generateExpirationTimeShortList = (): ExpirationTimeList => {
	const initial = getCurrent();
	const times = new Array(SHORT_TIMES_LENGTH)
		.fill(null)
		.map((_, idx) => initial.add(idx + SHORT_INTERVAL, 'minute'))
		.map(date => ({
			value: date.toDate(),
			stringified: date.format('HH:mm'),
		}));

	return {
		times: times,
	};
};

const LONG_INTERVAL = 15;
const MAX_HOUR = 23;
const MIN_HOUR = 5;
const MIN_GAP = 5;
const MAX_COUNT = 16;

export const generateExpirationTimeLongList = (): ExpirationTimeList => {
	const initial = getCurrent();

	const calculateNextQuarterHour = (start: Dayjs): Dayjs => {
		let next = start
			.add(LONG_INTERVAL - (start.minute() % LONG_INTERVAL), 'minute')
			.startOf('minute');
		if (next.diff(start, 'minute') <= MIN_GAP) {
			next = next.add(LONG_INTERVAL, 'minute');
		}
		return next;
	};

	const adjustToAllowedHours = (time: Dayjs): Dayjs => {
		const hour = time.hour();

		if (hour < MIN_HOUR) {
			return time.add(1, 'day').set('hour', MIN_HOUR).startOf('hour');
		}
		if (hour >= MAX_HOUR) {
			return time.add(1, 'day').set('hour', MIN_HOUR).startOf('hour');
		}

		return time;
	};

	const seenTimes = new Set<string>();
	const shortTermTimes: ExpirationTime[] = [];
	let current = calculateNextQuarterHour(initial);

	while (shortTermTimes.length < MAX_COUNT) {
		const adjusted = adjustToAllowedHours(current);
		const stringifiedTime = adjusted.format('HH:mm');
		if (!seenTimes.has(stringifiedTime)) {
			seenTimes.add(stringifiedTime);
			shortTermTimes.push({
				value: adjusted.toDate(),
				stringified: stringifiedTime,
			});
		}

		current = current.add(LONG_INTERVAL, 'minute');
	}

	const extendedTimes: ExpirationTime[] = [
		{ label: '12 часов', offset: { value: 12, unit: 'hour' } },
		{ label: '24 часа', offset: { value: 24, unit: 'hour' } },
		{ label: '7 дней', offset: { value: 7, unit: 'day' } },
		{ label: '14 дней', offset: { value: 14, unit: 'day' } },
		{ label: '30 дней', offset: { value: 30, unit: 'day' } },
		{ label: '90 дней', offset: { value: 90, unit: 'day' } },
	].map(({ label, offset }) => {
		const expiration = initial
			.add(offset.value, offset.unit)
			.add(1, 'hour')
			.startOf('hour');
		const adjusted = adjustToAllowedHours(expiration);
		return {
			label,
			value: adjusted.toDate(),
			stringified: adjusted.format('DD MMMM HH:mm'),
		};
	});

	return {
		times: shortTermTimes,
		extended: extendedTimes,
	};
};

const FIRST_VALUE = 1;
const PROGRESSION_DIFFERENCE = 50;
const PROGRESSION_LENGTH = 21;

export const generateExpirationTimeList = () => {
	return [
		FIRST_VALUE,
		...new Array(PROGRESSION_LENGTH)
			.fill(null)
			.map((_, idx) => idx * PROGRESSION_DIFFERENCE)
			.slice(1),
	];
};

export const getNextExpirationTime = (
	current: ExpirationTime,
	list:  any[],
): ExpirationTime => {
	return getNearestTime(current, list, 'next');
};

export const getPrevExpirationTime = (
	current: ExpirationTime,
	list: any[],
): ExpirationTime => {
	return getNearestTime(current, list, 'prev');
};
const getNearestTime = (
	current: ExpirationTime,
	list: ExpirationTimeList,
	direction: 'next' | 'prev',
): ExpirationTime => {
	const sortedList
		= direction === 'next'
			? list.sort((a, b) => a.value.getTime() - b.value.getTime())
			: list.sort((a, b) => b.value.getTime() - a.value.getTime());

	const comparator
		= direction === 'next'
			? (item: ExpirationTime) => item.value.getTime() > current.value.getTime()
			: (item: ExpirationTime) => item.value.getTime() < current.value.getTime();

	const idx = sortedList.findIndex(comparator);

	if (idx !== -1) {
		return sortedList[idx];
	}

	return current;
};

export const getExpirationTimeFromStringifyed = (
	stringified: string,
): ExpirationTime => {
	const [hours, minutes] = stringified.split(':');
	const date = getCurrent()
		.set('hours', +hours)
		.set('minutes', +minutes)
		.toDate();

	return {
		value: date,
		stringified,
	};
};

export const getNearestExpirationTime = (
	expirationTime: ExpirationTime,
	list: Array<UnwrapRefSimple<ExpirationTime>>,
): ExpirationTime | null => {
	if (list[0].value.getTime() > expirationTime.value.getTime()) {
		return list[0];
	}
	else {
		return null;
	}
};
