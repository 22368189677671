import Cookies from 'js-cookie';

export const useDealSumStore = defineStore('dealSum', {
	state: () => ({
		dealSum: 1,
		activeInvoiceBalance: 0 as number,
		list: [] as number[],
	}),

	getters: {
		maxDealSum: state => state.activeInvoiceBalance ?? 1000,
		blockBetting: (state) => {
			if (state.activeInvoiceBalance === 0) {
				return true;
			}
			return state.dealSum > state.activeInvoiceBalance;
		},
	},

	actions: {
		initializeDealSum() {
			const savedSum = Cookies.get('dealSum');
			if (savedSum) {
				this.dealSum = Math.max(1, parseFloat(savedSum));
			}
			this.generateList();
		},

		setDealSum(value: number) {
			const max = this.maxDealSum;
			this.dealSum = Math.min(Math.max(1, value), max);
			Cookies.set('dealSum', this.dealSum.toString(), { expires: 7 });
		},

		plusDealSum() {
			const increment = this.dealSum === 1 ? 49 : 50;
			this.setDealSum(this.dealSum + increment);
		},

		minusDealSum() {
			this.setDealSum(this.dealSum - 50);
		},

		generateList() {
			const max = this.maxDealSum;
			if (max < 1000) {
				const increments = [1, ...Array.from({ length: Math.ceil(max / 50) }, (_, i) => (i + 1) * 50)];
				this.list = increments.filter(value => value <= max);
			}
			else {
				this.list = [1, ...Array.from({ length: Math.ceil(1000 / 50) }, (_, i) => (i + 1) * 50)];
			}
		},
	},
});
