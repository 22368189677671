import { createCatalog } from '@legacy-studio/library';
import { apiMethods } from '../../infrastructure/api';
import {
	saveTransactionsList,
	saveTransactionsStatuses,
	saveTransactionsTypes,
} from '../../infrastructure/store';
import type { Filters } from '../../types';

export const catalog = createCatalog<Filters>({
	useProcessData: () => {
		const getList = apiMethods.useGetList();

		return async (filters, setTotal) => {
			const result = await getList(filters);

			if (result.isRight()) {
				const response = result.value;

				saveTransactionsList(response.items);
				saveTransactionsStatuses(response.filters.statuses);
				saveTransactionsTypes(response.filters.types);

				const total = Math.ceil(response.count / filters.limit);
				setTotal(total);
			}
		};
	},
	initial: {
		limit: 10,
		page: 1,
		filters: {
			type: 'all',
			status: 'all',
			date: null,
		},
	},
});
