import {
	ADMIN_REDIRECT_URL,
	PARTNERS_REDIRECT_URL,
	TRADER_REDIRECT_URL,
} from '~/src/shared/lib';

export const useRootUserUrls = () => {
	const localePath = useLocalePath();

	return computed(() => ({
		admin: localePath(ADMIN_REDIRECT_URL),
		trader: localePath(TRADER_REDIRECT_URL),
		partner: localePath(PARTNERS_REDIRECT_URL),
	}));
};
