import type { InferCallback } from '../../kernel';
import type { RuntimeNuxtHooks } from '#app/nuxt';

export const createEventHook = <
	T extends keyof RuntimeNuxtHooks,
	C extends InferCallback<RuntimeNuxtHooks, T> = InferCallback<
		RuntimeNuxtHooks,
		T
	>,
>(
	key: T,
) => {
	return {
		useOnEvent: (callback: C) => {
			const { hook } = useNuxtApp();
			const unsubscribe = hook(key, callback);
			onUnmounted(unsubscribe);
		},

		useTriggerEvent: () => {
			const { callHook } = useNuxtApp();

			return (...payloads: Parameters<InferCallback<RuntimeNuxtHooks, T>>) =>
				callHook(key, ...payloads);
		},
	};
};
