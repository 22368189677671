import { apiMethods } from '../../infrastructure/api';
import { saveTariffs } from '../../infrastructure/store';

export const useGetTariffs = () => {
	const getTariffs = apiMethods.useGetTariffs();

	return async () => {
		const result = await getTariffs();

		if (result.isRight()) {
			saveTariffs(result.value);
		}
		else {
			throw result.value;
		}
	};
};
