export enum TraderDrawers {
	Finances = 'finances',
	TopUp = 'topUp',
	Education = 'education',
	EducationSection = 'educationSection',
	EducationDetail = 'educationDetail',
	Withdrawal = 'withdrawal',
	PaymentMethod = 'paymentMethod',
	WithdrawalMethod = 'withdrawalMethod',
	Sum = 'sum',
	TransactionHistory = 'transactionHistory',
	Deals = 'deals',
	Tournaments = 'tournaments',
	Tournament = 'tournament',
	Bonuses = 'bonuses',
	BonusesDetail = 'bonusesDetail',
	DetailDeal = 'detailDeal',
	Help = 'help',
	HelpForm = 'helpForm',
	HelpBot = 'helpBot',

	Assets = 'assets',
	Drawing = 'drawing',
	Indicators = 'indicators',
	Interval = 'interval',
	Type = 'type',
	DealSum = 'dealSum',
	Time = 'time',
}
