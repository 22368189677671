import type { Deals } from '../../core/types';
import type { ClosedDealsResponse } from './getClosedDeals';
import { useApi } from '@/src/services/network';
import type { Response } from '~/src/shared/lib';
import { useActiveInvoice } from '~/src/features/invoices';
import { CLOSED_DEALS_LIMIT } from '~/src/features/terminal-deals/core/constants';

export type ActiveDealsResponse = ClosedDealsResponse;

export type ActiveDealsDTO = Response<ClosedDealsResponse>;

export const toDomain = (data: ActiveDealsDTO): Deals =>
	data.result.items.map(item => ({
		id: item.id,
		startDate: item.date,
		expirationDate: item.expiration_date,
		asset: item.trading_asset?.name,
		assetCode: item.trading_asset?.code,
		percent: item.asset_percent,
		sum: item.sum,
		income: item.income,
		invoice: item.invoice,
		startSum: item.asset_price,
		type: item.bet_dynamics.code,
	}));
const activeInvoice = useActiveInvoice();
export const useGetActiveDeals = () => {
	const api = useApi();
	return async () =>
		(await api<ActiveDealsDTO>('/DealsHistory/getActive/', {
			params: {
				invoice_id: activeInvoice.value?.id,
			},
		})).mapRight(toDomain);
};
