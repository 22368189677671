import dayjs from 'dayjs';
import type { TransactionHistory } from '../../types';
import { useApi } from '~/src/services/network';
import type { OptionsList } from '~/src/shared/kernel';
import type { Response } from '~/src/shared/lib';
import {formatAmount} from "@legacy-studio/library";

export type TransactionsDTO = Response<{
	filters: {
		type: OptionsList;
		status: OptionsList;
	};
	items: {
		id: string;
		user: string;
		invoice: null;
		order: string;
		date: DateString;
		type: string;
		paysystem?: {
			id: string;
			name: string;
			freekassa_id: string;
			currency: string;
			min_sum: null;
			max_sum: null;
			type: string;
		};
		status: {
			id: string;
			name: string;
			code: 'in_process' | 'fail' | 'success';
		};
		sum: number;
		account: null;
		cancellation_reason: null;
	}[];
	count: number;
	count_total: number;
}>;

const getStatusMode = (
	data: TransactionsDTO['result']['items'][0]['status']['code'],
) => {
	if (data === 'fail') {
		return 'progress';
	}
	else if (data === 'in_process') {
		return 'failure';
	}
	else {
		return 'success';
	}
};

export const toDomain = (data: TransactionsDTO): TransactionHistory => ({
	filters: {
		types: data.result.filters.type,
		statuses: data.result.filters.status,
	},
	items: data.result.items.map(item => ({
		date: item.date,
		number: +item.id,
		type: item.type,
		asset: item.paysystem?.name || '',
		status: {
			variant: getStatusMode(item.status.code),
			text: item.status.name,
		},
		sum: formatAmount(item.sum),
	})),
	count: data.result.count,
});

const fromDomain = (payload: any): any => {
	const apiPayload: any = {
		limit: payload.limit,
		page: payload.page,
		filters: Object.fromEntries(
			Object.entries({ ...payload.filters }).flatMap(([key, value]) => {
				if (key === 'date' && Array.isArray(value)) {
					const [date_from, date_to] = value;
					if (date_from) {
						return [
							['date_from', dayjs(date_from).utc().format('DD.MM.YYYY')],
							['date_to', date_to ? dayjs(date_to).utc().format('DD.MM.YYYY') : dayjs(date_from).utc().format('DD.MM.YYYY')],
						];
					}
					return [];
				}

				if (typeof value === 'string' || Array.isArray(value)) {
					return value.length !== 0 ? [[key, value]] : [];
				}

				return value != null ? [[key, value]] : [];
			}),
		),
		search: payload.search,
	};
	return apiPayload;
};

export const useGetList = () => {
	const api = useApi();
	return async (filters: object) =>
		(
			await api('/Transactions/get/', {
				body: fromDomain(filters),
				method: 'POST',
			})
		).mapRight(toDomain);
};
