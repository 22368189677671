export const useDrawerStore = defineStore('drawer', {
	state: () => ({
		activeItem: null as string | null,
	}),
	actions: {
		setActiveItem(link: string | null) {
			this.activeItem = link;
		},
		resetActiveItem() {
			this.activeItem = null;
		},
	},
});
