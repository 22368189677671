import { defineStore } from 'pinia';
import { TraderDrawerList } from '~/src/widgets/terminal/hooks';

export const useSidebarStore = defineStore('sidebar', {
	state: () => ({
		activeDrawer: null as string | null,
		content: null as any,
		history: [] as string[],
	}),

	getters: {
		primaryDrawers: () =>
			Object.entries(TraderDrawerList).filter(([, value]) => value.type === 'main'),

		secondaryDrawers: () =>
			Object.entries(TraderDrawerList).filter(([, value]) => value.type === 'additional'),
	},

	actions: {
		openDrawer(key: string, isInternal = false) {
			const drawer = TraderDrawerList[key];
			if (drawer) {
				if (this.activeDrawer && isInternal) {
					this.history.push(this.activeDrawer);
				}
				else if (!isInternal) {
					this.history = [];
				}
				this.activeDrawer = key;
				this.content = drawer.component;
			}
		},

		closeDrawer() {
			this.activeDrawer = null;
			this.content = null;
			this.history = [];
		},
		toggleDrawer(key: string, isInternal = false) {
			if (this.activeDrawer === key) {
				this.closeDrawer();
			}
			else {
				this.openDrawer(key, isInternal);
			}
		},
		goBack() {
			if (this.history.length > 0) {
				const previousDrawer = this.history.pop();
				if (previousDrawer) {
					this.activeDrawer = previousDrawer;
					this.content = TraderDrawerList[previousDrawer]?.component || null;
				}
			}
		},
	},
});
