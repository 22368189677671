import { useRootUserUrls } from '../../hooks';
import { readUser } from '../../infrastructure/store';
import { isAdmin, isPartner, isTrader } from '../domain';

export const useRootUserUrl = () => {
	const rootUserUrls = useRootUserUrls();

	return computed(() => {
		const user = readUser();

		if (!user) {
			return '';
		}

		if (isAdmin(user)) {
			return rootUserUrls.value.admin;
		}
		else if (isTrader(user)) {
			return rootUserUrls.value.trader;
		}
		else if (isPartner(user)) {
			return rootUserUrls.value.partner;
		}
		else {
			return '';
		}
	});
};
