import { createGlobalState } from '@vueuse/core';
import type { Cities } from '../../types';

type Countries = any; // TOOD

const useStore = createGlobalState(() => {
	const countries = ref<Countries | null>(null);
	const cities = ref<Cities | null>(null);

	return {
		countries,
		cities,
	};
});

export const saveCountries = (countries: Countries) => {
	useStore().countries.value = countries;
};

export const readCountries = () => {
	return useStore().countries.value;
};

export const useCountries = () => {
	const { countries } = useStore();
	return computed(() => countries.value || []);
};

export const saveCities = (cities: Cities) => {
	useStore().cities.value = cities;
};

export const readCities = () => {
	return useStore().cities.value;
};

export const useCities = () => {
	const { cities } = useStore();
	return computed(() => cities.value || []);
};
