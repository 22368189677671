import { apiMethods } from '../../infrastructure/api';
import { saveUser } from '../../infrastructure/store';

export const useGetUser = () => {
	const getUser = apiMethods.useGetUser();

	return async () => {
		const result = await getUser();

		if (result.isRight()) {
			saveUser(result.value);
		}
		else {
			throw result.value;
		}
	};
};
