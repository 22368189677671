import { createGlobalState } from '@vueuse/core';

const useStore = createGlobalState(() => {
	const isModalOpen = ref(false);

	return {
		isModalOpen,
	};
});

export const saveIsSupportModalOpen = (isOpen: boolean) => {
	useStore().isModalOpen.value = isOpen;
};

export const useIsSupportOpen = () => {
	const { isModalOpen } = useStore();
	return computed(() => isModalOpen.value);
};
