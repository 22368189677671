import { createGlobalState } from '@vueuse/core';
import type { PaymentMethodCategory } from '../../core/types';

const useStore = createGlobalState(() => {
	const paymentMethodsCategories = ref<Array<PaymentMethodCategory> | null>(
		null,
	);

	return {
		paymentMethodsCategories,
	};
});

export const usePaymentMethodsCategories = () => {
	const { paymentMethodsCategories } = useStore();

	return computed(() => paymentMethodsCategories.value);
};

export const savePaymentMethodsCategories = (
	categories: PaymentMethodCategory[],
) => {
	useStore().paymentMethodsCategories.value = categories;
};

export const readPaymentMethodsCategories = () => {
	return useStore().paymentMethodsCategories.value;
};
