import { createGlobalState } from '@vueuse/core';

const useStore = createGlobalState(() => {
	const isSidebarOpen = ref(false);

	return {
		isSidebarOpen,
	};
});

export const readIsSidebarOpen = () => useStore().isSidebarOpen.value;

export const saveIsSidebarOpen = (isOpen: boolean) => {
	useStore().isSidebarOpen.value = isOpen;
};

export const useIsSidebarOpen = () => {
	const isSidebarOpen = useStore().isSidebarOpen;
	return computed(() => isSidebarOpen.value);
};
