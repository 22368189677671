import type {
	SelectTournaments,
	SelectTournamentsCategories,
	SelectTournamentRating,
	SelectSelectedTournament,
} from '../../core/ports.input';
import type {
	ReadTournamentsCategories,
	SaveTournamentAvailable,
	SaveTournamentsCategories,
	SaveTournamentsItems,
	SaveSelectedTournamentItem,
	SaveSelectedTournamentRating,
	SaveSelectedTournamentsCategory,
} from '../../core/ports.output';
import type {
	TournamentsItems,
	TournamentsStatuses,
	ITournamentItem,
	TournamentRating,
	SelectTournamentCategory,
} from '../../core/types';

const _TournamentsCategories = ref<TournamentsStatuses | null>(null);
const _TournamentsSelectedCaregory = ref<string | null>(null);
const _TournamentsItems = ref<TournamentsItems | null>(null);
const _TournamentSelected = ref<ITournamentItem | null>(null);
const _TournamentSelectedRating = ref<TournamentRating | null>(null);

export const useTournamentsCategories: SelectTournamentsCategories = () =>
	_TournamentsCategories.value || [];

export const saveTournamentsCategories: SaveTournamentsCategories = categories => (_TournamentsCategories.value = categories);

export const useTournamentsSelectedCategory: SelectTournamentCategory = () =>
	_TournamentsSelectedCaregory.value || '';

export const saveTournamentsSelectedCategories: SaveSelectedTournamentsCategory
  = category => (_TournamentsSelectedCaregory.value = category);

export const readTournamentsCategories: ReadTournamentsCategories = () =>
	_TournamentsCategories.value;

export const saveTournamentsItems: SaveTournamentsItems = items =>
	(_TournamentsItems.value = items);

export const useTournamentsItems: SelectTournaments = () =>
	_TournamentsItems.value || [];

export const saveSelectedTournamentItem: SaveSelectedTournamentItem = item =>
	(_TournamentSelected.value = item);

export const useSelectedTournament: SelectSelectedTournament = () =>
	_TournamentSelected.value;

export const saveSelectedTournamentRating: SaveSelectedTournamentRating = item => (_TournamentSelectedRating.value = item);

export const useSelectedTournamentRating: SelectTournamentRating = () =>
	_TournamentSelectedRating.value;

export const saveTournamentAvailable: SaveTournamentAvailable = (id) => {
	const isAvailable = _TournamentsItems.value?.find(item => +item.id === id);
	if (isAvailable) {
		isAvailable.is_available = null;
	}
};
