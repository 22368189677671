<script setup lang="ts">
import type { UserAccountType } from '~/src/shared/lib';
import { UiIcon, type UiIconName } from '~/src/shared/ui';

const props = defineProps<{
	type: UserAccountType;
}>();

const iconMap: Record<UserAccountType, UiIconName> = {
	free: 'filled--free',
	premium: 'filled--premium',
	standart: 'filled--standart',
	gold: 'filled--gold',
	vip: 'filled--vip',
};

const icon = computed(() => iconMap[props.type]);
</script>

<template>
	<UiIcon
		:name="icon"
		class="text-[14px]"
	/>
</template>
