import type { ErrorPayload } from '../../kernel';

export const ERROR_CODES = {
	VALIDATION_ERROR: 422,
	TOO_MANY_REQUESTS: 429,
	BAD_REQUEST: 400,
	INTERNAL_SERVER_ERROR: 500,
	UNAUTHORIZED: 401,
} as const;

export class NetworkError extends Error {
	constructor(
		message: string,
		public code: number,
	) {
		super(message);
	}
}

export class NetworkValidationError<T extends string = string> extends Error {
	code = ERROR_CODES.VALIDATION_ERROR;

	constructor(
		message: string,
		public errors: ErrorPayload<T> = {},
	) {
		super(message);
	}
}

export class NetworkBadRequestError extends Error {
	code = ERROR_CODES.BAD_REQUEST;
}

export class NetworkInternalServerError extends Error {
	code = ERROR_CODES.INTERNAL_SERVER_ERROR;
}
