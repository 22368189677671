import {
	useLoginUrl,
	useGetUser,
	useIsUserAdmin,
	useIsUserAuth,
	useRootUserUrl,
	useIsUserTrader,
	useIsUserPartner,
} from '@/src/features/user';
import {
	ADMIN_AUTH_MIDDLEWARE,
	NO_AUTH_MIDDLEWARE,
	PARTNERS_AUTH_MIDDLEWARE,
	TRADER_AUTH_MIDDLEWARE,
} from '~/src/shared/lib';
import { useErrorHandler } from '~/src/services/error';

export default defineNuxtPlugin(async () => {
	await useGetUser()().catch(useErrorHandler());
	const isUserAdmin = useIsUserAdmin();
	const isUserTrader = useIsUserTrader();
	const isUserPartner = useIsUserPartner();
	const isUserAuth = useIsUserAuth();
	const loginUrl = useLoginUrl();
	const rootUserUrl = useRootUserUrl();

	addRouteMiddleware(ADMIN_AUTH_MIDDLEWARE, () => {
		if (!isUserAdmin.value) {
			return navigateTo(rootUserUrl.value || loginUrl.value);
		}
	});

	addRouteMiddleware(TRADER_AUTH_MIDDLEWARE, () => {
		if (!isUserTrader.value && !isUserAdmin.value) {
			return navigateTo(rootUserUrl.value || loginUrl.value);
		}
	});

	addRouteMiddleware(PARTNERS_AUTH_MIDDLEWARE, () => {
		if (!isUserPartner.value && !isUserAdmin.value) {
			return navigateTo(rootUserUrl.value || loginUrl.value);
		}
	});

	addRouteMiddleware(NO_AUTH_MIDDLEWARE, () => {
		if (isUserAuth.value) {
			return navigateTo(rootUserUrl.value);
		}
	});
});
