import { all, createValidationSchema, rules } from '@legacy-studio/library';
import type { NewPasswordForm } from '../../types';

export const createNewPasswordSchema = createValidationSchema<NewPasswordForm>({
	email: ({ email }) => all([rules.required, rules.email])(email),
	agreement: ({ agreement }) => rules.required(agreement),
	password: ({ password }) => rules.required(password),
	confirmPassword: ({ password, confirmPassword }) =>
		rules.passwordConfirm(password, confirmPassword),
});
