import type { TariffItems } from '../../types';
import { useApi } from '@/src/services/network';
import type { Response, UserAccountType } from '~/src/shared/lib';

export type ApiResponse = Response<
	{
		id: string;
		name: string;
		code: UserAccountType;
		sum: string | null;
		day_limit: number;
		week_limit: number;
		withdrawal_processing_limit: number;
		income_percent: number;
		demo_increase: number;
	}[]
>;

export const toDomain = (data: ApiResponse): TariffItems =>
	data.result.map(item => ({
		id: item.id,
		name: item.name,
		code: item.code,
		sum: (item.sum ? +item.sum : 0) || 0,
		dayLimit: item.day_limit,
		weekLimit: item.week_limit,
		withdrawalProcessingLimit: item.withdrawal_processing_limit,
		incomePercent: item.income_percent,
		demoIncrease: item.demo_increase,
	}));

export const useGetTariffs = () => {
	const api = useApi();

	return async () =>
		(await api<ApiResponse>('/Tariffs/get/')).mapRight(toDomain);
};
