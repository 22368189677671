<script setup lang="ts">
// import TariffsCard from './Card.vue';
import TariffCard from './OtherCard.vue';
import { useTariffs } from '~/src/features/tariffs';

const tariffs = useTariffs();
</script>

<template>
	<!--	<div -->
	<!--		class="flex flex-col items-center gap-8 xl:grid xl:grid-cols-5 xl:items-start xl:gap-4" -->
	<!--	> -->
	<!--		&lt;!&ndash; TODO &ndash;&gt; -->
	<!--		<TariffsCard type="free" /> -->
	<!--		<TariffsCard type="standart" /> -->
	<!--		<TariffsCard type="gold" /> -->
	<!--		<TariffsCard type="premium" /> -->
	<!--		<TariffsCard type="vip" /> -->
	<!--	</div> -->
	<div
		class="flex flex-col items-center gap-8 xl:grid xl:grid-cols-5 xl:items-start xl:gap-4"
	>
		<TariffCard
			v-for="tariff in tariffs"
			:key="tariff.id"
			:tariff="tariff"
		/>
	</div>
</template>
