import { createGlobalState } from '@vueuse/core';
import type {
	TransactionsTypes,
	TransactionsStatuses,
	TransactionsList,
} from '../../core/types';

const useStore = createGlobalState(() => {
	const list = ref<TransactionsList>([]);
	const statuses = ref<TransactionsStatuses>([]);
	const types = ref<TransactionsTypes>([]);

	return {
		list,
		statuses,
		types,
	};
});

export const saveTransactionsList = (list: TransactionsList) =>
	(useStore().list.value = list);

export const saveTransactionsStatuses = (statuses: TransactionsStatuses) =>
	(useStore().statuses.value = statuses);

export const saveTransactionsTypes = (types: TransactionsTypes) =>
	(useStore().types.value = types);

export const useTransactionsList = () => {
	const { list } = useStore();

	return computed(() => list.value || []);
};

export const useTransactionsStatuses = () => {
	const { statuses } = useStore();

	return computed(() => statuses.value);
};

export const useTransactionsTypes = () => {
	const { types } = useStore();

	return computed(() => types.value);
};
