import { defineAsyncComponent } from 'vue';
import type { ComputedRef } from 'vue';

import { TraderDrawers } from '~/src/widgets/terminal/enum';
import { useOpenedDealsSectionName } from '~/src/features/terminal-deals';
import { useBonusesSelected } from '~/src/features/bonuses';
import { useSelectedTournament } from '~/src/features/tournaments/infrastructure/store';
import { useChartType } from '~/src/features/terminal';
import { useEducationStore } from '~/src/features/education/infrastructure/store';

const openedDealsSectionName = useOpenedDealsSectionName();

const selectTournamentName = computed(() => {
	const tournament = useSelectedTournament();
	return tournament ? tournament.name : '';
});

const selectBonusesName = computed(() => {
	const bonusesSelected = useBonusesSelected();
	return bonusesSelected.value ? bonusesSelected.value?.title : '';
});

const selectIntervalName = computed(() => {
	const currentType = useChartType();
	return (currentType.value === 'bars' || currentType.value === 'candlestick') ? 'Временной размер свечи' : 'Интервал';
});

const selectEducationName = computed(() => {
	const educationStore = useEducationStore();
	const { currentTitle } = storeToRefs(educationStore);
	return currentTitle.value;
});
const selectEducationNameDetail = computed(() => {
	const educationStore = useEducationStore();
	const { currentTitleSecond } = storeToRefs(educationStore);
	return currentTitleSecond.value;
});
function createDrawer(
	title: ComputedRef<string> | string,
	icon: string,
	component: any,
	type: 'main' | 'additional' | 'nested' | 'terminal',
	fullscreen: boolean = false,
	direction: 'left' | 'right' = 'left',
) {
	const computedTitle = computed(() => {
		return typeof title === 'string' ? title : title.value;
	});

	return {
		title: computedTitle,
		icon,
		component,
		type,
		fullscreen,
		direction,
	};
}

const HelpList = defineAsyncComponent(() => import('~/src/features/help/ui/List.vue'));
const HelpBot = defineAsyncComponent(() => import('~/src/features/help/ui/Bot.vue'));
const HelpForm = defineAsyncComponent(() => import('~/src/features/help/ui/Form.vue'));

const DealsHistorySection = defineAsyncComponent(() => import('~/src/features/terminal-deals/ui/HistorySection.vue'));
const OpenedDealsSectionDetail = defineAsyncComponent(() => import('~/src/features/terminal-deals/ui/OpenedSectionDetail.vue'));

const Finances = defineAsyncComponent(() => import('~/src/widgets/terminal/ui/sidebarContent/Finances.vue'));
const TopUpSection = defineAsyncComponent(() => import('~/src/features/top-up/ui/Section.vue'));
const PaymentMethodList = defineAsyncComponent(() => import('~/src/features/payment-method/ui/List.vue'));
const SumList = defineAsyncComponent(() => import('~/src/features/sum/ui/List.vue'));
const Withdrawal = defineAsyncComponent(() => import('~/src/widgets/terminal/ui/sidebarContent/Withdrawal.vue'));
const TerminalTransactionsHistoryList = defineAsyncComponent(() => import('~/src/features/transactions-history/ui/Section.vue'));

const TournamentsList = defineAsyncComponent(() => import('~/src/features/tournaments/ui/List.vue'));
const TournamentItem = defineAsyncComponent(() => import('~/src/features/tournaments/ui/Item.vue'));

const BonusesList = defineAsyncComponent(() => import('~/src/features/bonuses/ui/List.vue'));
const BonusesItem = defineAsyncComponent(() => import('~/src/features/bonuses/ui/Item.vue'));

const Drawing = defineAsyncComponent(() => import('~/src/features/terminal-drawers/ui/Items/Drawing.vue'));
const Indicators = defineAsyncComponent(() => import('~/src/features/terminal-drawers/ui/Items/Indicators.vue'));
const Type = defineAsyncComponent(() => import('~/src/features/terminal-drawers/ui/Items/Type.vue'));
const Assets = defineAsyncComponent(() => import('~/src/features/assets/ui/Section.vue'));
const Interval = defineAsyncComponent(() => import('~/src/features/terminal-drawers/ui/Items/Interval.vue'));

const TerminalExpirationTimeSection = defineAsyncComponent(() => import('~/src/features/terminal-expiration-time/ui/Section.vue'));
const TerminalDealSumSection = defineAsyncComponent(() => import('~/src/features/terminal-deal-sum/ui/Section.vue'));

const EducationList = defineAsyncComponent(() => import('~/src/features/education/ui/List.vue'));
const EducationSection = defineAsyncComponent(() => import('~/src/features/education/ui/Section.vue'));
const EducationDetail = defineAsyncComponent(() => import('~/src/features/education/ui/Detail.vue'));

export function getTraderDrawerList() {
	return {
		[TraderDrawers.Deals]: createDrawer('История сделок', 'base--fill--clock', DealsHistorySection, 'main'),
		[TraderDrawers.DetailDeal]: createDrawer(openedDealsSectionName, '', OpenedDealsSectionDetail, 'nested'),

		[TraderDrawers.Finances]: createDrawer('Финансы', 'base--fill--credit-card', Finances, 'main'),
		[TraderDrawers.TopUp]: createDrawer('Пополнить', '', TopUpSection, 'nested'),
		[TraderDrawers.Withdrawal]: createDrawer('Вывод', '', Withdrawal, 'nested'),
		[TraderDrawers.PaymentMethod]: createDrawer('Платежный метод', '', PaymentMethodList, 'nested'),
		[TraderDrawers.WithdrawalMethod]: createDrawer('Способ вывода', '', PaymentMethodList, 'nested'),
		[TraderDrawers.Sum]: createDrawer('Сумма платежа', '', SumList, 'nested'),
		[TraderDrawers.TransactionHistory]: createDrawer('История транзакций', '', TerminalTransactionsHistoryList, 'nested', true),

		[TraderDrawers.Tournaments]: createDrawer('Турниры', 'base--fill--cup', TournamentsList, 'main'),
		[TraderDrawers.Tournament]: createDrawer(selectTournamentName, '', TournamentItem, 'nested'),

		[TraderDrawers.Education]: createDrawer('Обучение', 'base--fill--academic-cap', EducationList, 'main'),
		[TraderDrawers.EducationSection]: createDrawer(selectEducationName, '', EducationSection, 'nested'),
		[TraderDrawers.EducationDetail]: createDrawer(selectEducationNameDetail, '', EducationDetail, 'nested'),

		[TraderDrawers.Bonuses]: createDrawer('Акции', 'base--fill--receipt-tax', BonusesList, 'main'),
		[TraderDrawers.BonusesDetail]: createDrawer(selectBonusesName, '', BonusesItem, 'nested'),

		[TraderDrawers.Help]: createDrawer('Помощь', 'base--fill--question-mark-circle', HelpList, 'additional'),
		[TraderDrawers.HelpBot]: createDrawer('Поддержка в Telegram', '', HelpBot, 'nested'),
		[TraderDrawers.HelpForm]: createDrawer('Форма для связи', '', HelpForm, 'nested'),

		[TraderDrawers.Drawing]: createDrawer('Рисование', '', Drawing, 'terminal'),
		[TraderDrawers.Indicators]: createDrawer('Индикаторы', '', Indicators, 'terminal'),
		[TraderDrawers.Type]: createDrawer('Тип графика', '', Type, 'terminal'),
		[TraderDrawers.Assets]: createDrawer('Активы', '', Assets, 'terminal'),
		[TraderDrawers.Interval]: createDrawer(selectIntervalName, '', Interval, 'terminal'),

		[TraderDrawers.Time]: createDrawer('Экспирация', '', TerminalExpirationTimeSection, 'terminal', false, 'right'),
		[TraderDrawers.DealSum]: createDrawer('Сумма', '', TerminalDealSumSection, 'terminal', false, 'right'),
	};
}

export const TraderDrawerList = getTraderDrawerList();
