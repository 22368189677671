<script setup lang="ts">
import { UiIcon } from './Icon';

const props = withDefaults(
	defineProps<{
		size: 'xs' | 'sm' | 'base' | 'l' | 'xl';
		withText?: boolean;
		text?: string;
	}>(),
	{
		withText: false,
		text: 'BBroker',
	},
);

const iconClasses = computed(() => ({
	'text-[24px]': props.size === 'xs',
	'text-[32px]': props.size === 'sm',
	'text-[48px]': props.size === 'base',
	'text-[64px]': props.size === 'l',
	'text-[96px]': props.size === 'xl',
}));

const classes = computed(() => ({
	'flex items-center font-semibold text-gray-900 dark:text-white': true,
	'gap-2 text-lg': props.size === 'xs',
	'gap-[9px] text-2xl': props.size === 'sm',
	'gap-4 text-4xl': props.size === 'base',
	'gap-4 text-6xl': props.size === 'l',
	'gap-5 text-8xl': props.size === 'xl',
}));
</script>

<template>
	<div :class="classes">
		<UiIcon
			name="filled--logo"
			:class="iconClasses"
			filled
		/>

		<span
			v-if="withText && text"
			class="font-semibold"
		>{{ text }}</span>
	</div>
</template>
