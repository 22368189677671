import {
	useIsUserAuth,
	useRootUserUrl,
} from '@/src/features/user';

export default defineNuxtRouteMiddleware((to) => {
	const isUserAuth = useIsUserAuth();
	const rootUserUrl = useRootUserUrl();

	if (isUserAuth.value && to.path === '/') {
		return navigateTo(rootUserUrl.value);
	}
});
