import type { CreateDealData } from '../../core/types';
import { createEventHook } from '~/src/shared/lib';

type TermianlCreateDeal = (data: CreateDealData) => void;
type TerminalCheckDeal = (data: { id: string }) => void;

const HOOK_NAME = 'termianl:create-deal';
const HOOK_NAME_CHECK = 'termianl:check-deal';
declare module '#app/nuxt' {
	interface RuntimeNuxtHooks {
		[HOOK_NAME]: TermianlCreateDeal;
		[HOOK_NAME_CHECK]: TerminalCheckDeal;
	}
}

const {
	useOnEvent: useSubscribeTermianlCreateDeal,
	useTriggerEvent: useTriggerTermianlCreateDeal,
} = createEventHook(HOOK_NAME);

const {
	useOnEvent: useSubscribeTerminalCheckDeal,
	useTriggerEvent: useTriggerTerminalCheckDeal,
} = createEventHook(HOOK_NAME_CHECK);

export const terminalBus = {
	useSubscribeTermianlCreateDeal,
	useTriggerTermianlCreateDeal,
	useSubscribeTerminalCheckDeal,
	useTriggerTerminalCheckDeal,
};
