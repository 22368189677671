import { CLOSED_DEALS_LIMIT } from '../../core/constants';
import type { ClosedDealsPageData, DealId } from '../../core/types';
import type { Response } from '~/src/shared/lib';
import { useApi } from '@/src/services/network';
import { useActiveInvoice } from '~/src/features/invoices';

export type ClosedDealsResponse = {
	count: number;
	items: {
		invoice: string;
		date: DateString;
		id: DealId;
		sum: number;
		expiration_date: DateString;
		bet_dynamics: {
			id: string;
			name: string;
			code: 'up' | 'down';
		};
		asset_price: number;
		asset_percent: number;
		expiration_asset_price: number;
		income: number;
		trading_asset: {
			id: string;
			name: string;
			code: string;
			type: string;
			percent_free: number;
			percent_standart: number;
			percent_gold: number;
			percent_premium: number;
			percent_vip: number;
			active: string;
		};
	}[];
};

export type DealsHistoryDTO = Response<ClosedDealsResponse>;

export const toDomain = (data: DealsHistoryDTO): ClosedDealsPageData => ({
	count: data.result.count,
	items: data.result.items.map(item => ({
		id: item.id,
		startDate: item.date,
		expirationDate: item.expiration_date,
		expirationAssetPrice: item.expiration_asset_price,
		asset: item.trading_asset?.name || '1',
		percent: item.asset_percent,
		sum: item.sum,
		income: item.income,
		invoice: item.invoice,
		startSum: item.asset_price,
		type: item.bet_dynamics.code,
	})),
});
const activeInvoice = useActiveInvoice();
export const useGetClosedDeals = () => {
	const api = useApi();

	return async (page: number) =>
		(
			await api<DealsHistoryDTO>('/DealsHistory/getCompleted/', {
				params: {
					invoice_id: activeInvoice.value?.id,
					page,
					limit: CLOSED_DEALS_LIMIT,
				},
			})
		).mapRight(toDomain);
};
