import { createGlobalState } from '@vueuse/core';
import type { BonusesItem, BonusesItems } from '../../types';

const useStore = createGlobalState(() => {
	const items = ref<BonusesItems | null>(null);
	const selected = ref<BonusesItem | null>(null);

	return {
		items,
		selected,
	};
});

export const useBonusesItems = () => {
	const { items } = useStore();
	return computed(() => items.value || []);
};

export const useBonusesSelected = () => {
	const { selected } = useStore();
	return computed(() => selected.value);
};

export const saveBonusesItems = (items: BonusesItems) => {
	const { items: _items } = useStore();
	_items.value = items;
};

export const saveBonusesSelected = (item: BonusesItem) => {
	const { selected } = useStore();
	selected.value = item;
};
