interface Notification {
	id: number;
	title: string;
	text?: string;
	textColor?: string | null;
}

export const useNotificationStore = defineStore('notification', {
	state: () => ({
		notifications: [] as Notification[],
	}),
	actions: {
		addNotification(title: string, text: string = '', income: number | null = null) {
			const id = Date.now();
			const textColor = income && income > 0
				? 'text-green-400'
				: 'text-red-400';

			this.notifications.push({ id, title, text, textColor });

			setTimeout(() => {
				this.removeNotification(id);
			}, 5000);
		},
		removeNotification(id: number) {
			this.notifications = this.notifications.filter(n => n.id !== id);
		},
	},
});
