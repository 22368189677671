export const USER_ACCOUNT_TYPE = {
	free: 'free',
	standart: 'standart',
	gold: 'gold',
	premium: 'premium',
	vip: 'vip',
} as const;

export const USER_ROLES = {
	admin: 'admin',
	partner: 'partner',
	trader: 'trader',
} as const;

export const ADMIN_AUTH_MIDDLEWARE = 'admin-auth';
export const PARTNERS_AUTH_MIDDLEWARE = 'partners-auth';
export const TRADER_AUTH_MIDDLEWARE = 'trader-auth';
export const NO_AUTH_MIDDLEWARE = 'no-auth';

export const LOGIN_REDIRECT_URL = '/login';
export const PARTNERS_REDIRECT_URL = '/partners/statistics';
export const ADMIN_REDIRECT_URL = '/admin';
export const TRADER_REDIRECT_URL = '/terminal';
