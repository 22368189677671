import { createGlobalState } from '@vueuse/core';

interface TradersPayload {
	date: string;
	filterValue?: string;
	filterType?: string | number | undefined;
}

const useStore = createGlobalState(() => {
	const data = ref<any | null>(null);
	const type = ref<any | null>(null);
	const date = ref<any | null>(null);
	const expandedRows = ref<number[]>([]);
	const currentRowId = ref<number | string>();
	const tradersPayload = ref<TradersPayload | null>(null);
	return {
		data,
		tradersPayload,
		expandedRows,
		currentRowId,
		type,
		date,
	};
});

export const saveTableByDaysData = (data: any) => {
	const store = useStore();
	store.data.value = data;
};
export const useTableByDaysData = () => {
	const store = useStore();
	return computed(() => store.data.value);
};
export const saveExpandedRows = (data: any) => {
	const store = useStore();
	store.expandedRows.value = data;
};
export const useExpandedRows = () => {
	const store = useStore();
	return computed(() => store.expandedRows.value);
};

export const saveCurrentDate = (data: any) => {
	const store = useStore();
	store.date.value = data;
};
export const useCurrentDate = () => {
	const store = useStore();
	return computed(() => store.date.value);
};
export const saveCurrentType = (data: any) => {
	const store = useStore();
	store.type.value = data;
};
export const useCurrentType = () => {
	const store = useStore();
	return computed(() => store.type.value);
};
export const saveTradersPayload = (date: any, filterType?: string, filterValue?: number | string | undefined) => {
	const store = useStore();
	store.tradersPayload.value = {
		date,
		filterType,
		filterValue,
	};
};
export const useTradersPayload = () => {
	const store = useStore();
	return computed(() => store.tradersPayload.value);
};
