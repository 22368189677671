export const LOGIN_TAB = {
	login: 'login',
	resetPassword: 'resetPassword',
	newPassword: 'newPassword',
} as const;

export const TOKEN_STORAGE_KEY = 'jwt_token';

export const REGISTRATION_TAB = {
	sendCode: 'sendCode',
	codeConfirmation: 'codeConfirmation',
	personalData: 'personalData',
} as const;
