import { TOKEN_STORAGE_KEY } from '../constants';

export class TokenService {
	public static get() {
		return localStorage.getItem(TOKEN_STORAGE_KEY) || '';
	}

	public static set(val: string) {
		localStorage.setItem(TOKEN_STORAGE_KEY, val);
	}

	public static clear() {
		localStorage.removeItem(TOKEN_STORAGE_KEY);
	}
}
