import { storeFactory } from '../../infrastructure/store';
import {
	popWidgetDrawerNames,
	addWidgetDrawerName,
	sliceWidgetDrawerNames,
} from '../domain';

export const createDrawers = <T extends string>() => {
	const { useDrawerNames, readDrawerNames, saveDrawerNames }
    = storeFactory<T>();

	const back = () => {
		const names = readDrawerNames();
		const updatedNames = popWidgetDrawerNames<T>(names as Array<T>);
		saveDrawerNames(updatedNames);
	};

	const close = (name?: T) => {
		if (name) {
			const names = readDrawerNames();
			const updatedNames = sliceWidgetDrawerNames<T>(names as Array<T>, name);
			saveDrawerNames(updatedNames);
		}
		else {
			saveDrawerNames([]);
		}
	};

	const open = (name: T, { nested } = { nested: false }) => {
		if (nested) {
			const names = readDrawerNames();
			const updatedNames = addWidgetDrawerName<T>(names as Array<T>, name);
			saveDrawerNames(updatedNames);
		}
		else {
			saveDrawerNames([name]);
		}
	};

	const useIsOpen = (name: T) => {
		const names = useDrawerNames();
		return computed(() => (names.value as Array<T>).includes(name));
	};

	const useHasPrevDrawer = (name: T) => {
		const names = useDrawerNames();
		return computed(() => {
			const idx = (names.value as Array<T>).indexOf(name);
			return idx > 0;
		});
	};

	return {
		back,
		close,
		open,
		useDrawerNames,
		useIsOpen,
		useHasPrevDrawer,
	};
};
