export const PAGE_DRAWERS = {
	finances: 'finances',
	topUp: 'topUp',
	education: 'education',
	withdrawal: 'withdrawal',
	paymentMethod: 'paymentMethod',
	assets: 'assets',
	withdrawalMethod: 'withdrawalMethod',
	sum: 'sum',
	transactionHistory: 'transactionHistory',
	tariffs: 'tariffs',
	deals: 'deals',
	tournaments: 'tournaments',
	tournament: 'tournament',
	bonuses: 'bonuses',
	bonusesDetail: 'bonusesDetail',
	detailDeal: 'detailDeal',
	help: 'help',
	helpForm: 'helpForm',
	helpBot: 'helpBot',
} as const;
