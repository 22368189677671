import { all, createValidationSchema, rules } from '@legacy-studio/library';
import type { EditPersonalPartnerForm, EditPersonalTraderForm } from '~/src/features/profile/types';

export const editPersonalTraderSchema = createValidationSchema<EditPersonalTraderForm>({
	firstName: ({ firstName }) => rules.required(firstName),
	lastName: ({ lastName }) => rules.required(lastName),
	gender: ({ gender }) => rules.required(gender),
	phone: ({ phone }) => all([rules.phone])(phone),
	country: ({ country }) => rules.required(country),
	birthday: ({ birthday }) => rules.required(birthday),
	citizenship: ({ citizenship }) => rules.required(citizenship),
	city: ({ city }) => rules.required(city),
	address: ({ address }) => rules.required(address),
});

export const editPersonalPartnerSchema = createValidationSchema<EditPersonalPartnerForm>({
	firstName: ({ firstName }) => rules.required(firstName),
	lastName: ({ lastName }) => rules.required(lastName),
	phone: ({ phone }) => all([rules.phone])(phone),
	country: ({ country }) => rules.required(country),
});
