import type { AssetItem } from '../../types';
import { createEventHook } from '~/src/shared/lib';

type AssetsSelectAsset = (asset: AssetItem) => void;

const ASSETS_SELECT_ASSET = 'assets:selet-asset';

declare module '#app/nuxt' {
	interface RuntimeNuxtHooks {
		[ASSETS_SELECT_ASSET]: AssetsSelectAsset;
	}
}

export const {
	useOnEvent: useOnAssetSelected,
	useTriggerEvent: useTriggerSelectAsset,
} = createEventHook(ASSETS_SELECT_ASSET);
