import type { UserCurrent } from '../../types';
import { useApi } from '@/src/services/network';
import { USER_ACCOUNT_TYPE, USER_ROLES } from '~/src/shared/lib';
import type {
	Response,
	UserAccountType,
	UserColorCode,
	UserGroupId,
	UserId,
} from '~/src/shared/lib';

export type UserGroupsDTO = Array<{
	id: string;
	name: string;
	code: UserGroupId;
}>;

export type UserDTO = Response<{
	id: UserId;
	referal_code: null | string;
	login: string;
	email: string;
	name: string;
	last_name: string;
	photo: string;
	phone_number: string;
	group: UserGroupsDTO;
	color: UserColorCode | null;
	date_register: DateString;
	language: string;
	country: null | string;
	tariff: {
		id: string;
		name: string;
		code: UserAccountType;
	};
}>;

export const getUserGroupId = (groups: UserGroupsDTO) => {
	if (!groups) {
		return USER_ROLES.trader;
	}
	if (groups.length === 1 && groups[0].code === USER_ROLES.trader) {
		return USER_ROLES.trader;
	}
	// todo убрать
	if (groups.length === 1 && groups[0].code === USER_ROLES.partner) {
		return USER_ROLES.partner;
	}
	// if (groups.length === 2
	// 	&& groups.some(group => group.code === USER_ROLES.trader)
	// 	&& groups.some(group => group.code === USER_ROLES.partner)) {
	// 	return USER_ROLES.partner;
	// }

	if (groups.length === 1 && groups[0].code === USER_ROLES.admin) {
		return USER_ROLES.admin;
	}

	return USER_ROLES.trader;
};

export const toDomain = (user: UserDTO): UserCurrent => ({
	id: user.result.id,
	login: user.result.login,
	email: user.result.email,
	firstName: user.result.name,
	lastName: user.result.last_name,
	photo: user.result.photo,
	phone: user.result.phone_number,
	colorCode: user.result.color || '1',
	accountType: user.result.tariff?.code ?? USER_ACCOUNT_TYPE.free,
	groupId: getUserGroupId(user.result.group),
	language: user.result.language,
	registrationDate: user.result.date_register,
	referralCode: user.result.referal_code,
	country: user.result.country,
});

export const useGetUser = () => {
	const api = useApi();

	return async () => (await api<UserDTO>('/User/get/')).mapRight(toDomain);
};
