import { createGlobalState } from '@vueuse/core';

export const storeFactory = <T extends string>() => {
	const useStore = createGlobalState(() => {
		const drawerNames = ref<Array<T>>([]);

		return {
			drawerNames,
		};
	});

	const useDrawerNames = () => {
		const { drawerNames } = useStore();
		return computed(() => drawerNames.value);
	};

	const saveDrawerNames = (names: Array<T>) => {
		(useStore().drawerNames.value as Array<T>) = names;
	};

	const readDrawerNames = () => {
		return useStore().drawerNames.value;
	};

	return {
		useDrawerNames,
		saveDrawerNames,
		readDrawerNames,
	};
};
