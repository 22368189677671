import type {
	SelectWithdrawalMethod,
	SelectWithdrawalResult,
} from '../../core/ports.input';
import type {
	ReadWithdrawalMethod, ResetWithdrawalMethod,
	SaveWithdrawalMethod,
	SaveWithdrawalResult,
} from '../../core/ports.output';
import type { WithdrawalResult } from '../../core/types';
import type { PaymentMethodItem } from '~/src/features/payment-method';

const _withdrawalMethod = ref<PaymentMethodItem | null>(null);
const _withdrawalResult = ref<WithdrawalResult | null>(null);

export const useWithdrawalMethod: SelectWithdrawalMethod = () =>
	_withdrawalMethod.value;
export const useWithdrawalResult: SelectWithdrawalResult = () =>
	_withdrawalResult.value;

export const saveWithdrawalMethod: SaveWithdrawalMethod = method =>
	(_withdrawalMethod.value = method);
export const resetWithdrawalMethod: ResetWithdrawalMethod = () =>
	(_withdrawalMethod.value = null);

export const saveWithdrawalResult: SaveWithdrawalResult = result =>
	(_withdrawalResult.value = result);

export const readWithdrawalMethod: ReadWithdrawalMethod = () =>
	_withdrawalMethod.value;
