export const useEducationStore = defineStore('education', {
	state: () => ({
		content: null as { id: string; name: string; items: Array<{ id: string; name: string; text: string }> }[] | null,
		selectedItems: [] as Array<{ id: string; name: string; text: string }>,
		selectedText: '' as string,
		selectedFirstLevelId: '' as string,
		selectedSecondLevelId: '' as string,
		currentTitle: '' as string,
		currentTitleSecond: '' as string,
	}),

	getters: {
		list: (state) => {
			return state.content?.map(item => ({ id: item.id, name: item.name })) || [];
		},

		itemsBySelectedFirstLevelId: (state) => {
			return state.content?.find(item => item.id === state.selectedFirstLevelId)?.items || [];
		},
	},

	actions: {

		saveEducationItems(data: any) {
			this.content = data;
		},

		selectFirstLevelItem(id: string) {
			this.selectedFirstLevelId = id;
			this.selectedItems = this.itemsBySelectedFirstLevelId;
			this.selectedSecondLevelId = '';
			this.selectedText = '';
			const selectedItem = this.content?.find(item => item.id === id);
			this.currentTitle = selectedItem?.name || '';
		},

		selectSecondLevelItem(id: string) {
			this.selectedSecondLevelId = id;
			const selectedItem = this.selectedItems.find(item => item.id === id);
			this.currentTitleSecond = selectedItem?.name || '';
			this.selectedText = selectedItem?.text || '';
		},
	},
});
