import { createGlobalState } from '@vueuse/core';
import {
	generateExpirationTimeShortList,
	generateExpirationTimeLongList,
	getInitialExpirationTime,
} from '../../core/domain';

import type { ExpirationTime, ExpirationTimeList } from '../../types';

const useStore = createGlobalState(() => {
	const expirationTime = ref<ExpirationTime>(getInitialExpirationTime());
	const expirationShortList = ref<ExpirationTimeList>(
		generateExpirationTimeShortList(),
	);
	const expirationLongList = ref<ExpirationTimeList>(
		generateExpirationTimeLongList(),
	);

	return {
		expirationTime,
		expirationShortList,
		expirationLongList,
	};
});

export const useExpirationTime = () => {
	const { expirationTime } = useStore();
	return computed(() => expirationTime.value);
};

export const useExpirationTimeShortList = () => {
	const { expirationShortList } = useStore();
	return computed(() => expirationShortList.value);
};

export const useExpirationTimeLongList = () => {
	const { expirationLongList } = useStore();
	return computed(() => expirationLongList.value);
};

export const saveExpirationTime = (expirationTime: ExpirationTime) => {
	useStore().expirationTime.value = expirationTime;
};

export const saveExpirationTimeShortList = (list: ExpirationTimeList) => {
	useStore().expirationShortList.value = list;
};

export const saveExpirationTimeLongList = (list: ExpirationTimeList) => {
	useStore().expirationLongList.value = list;
};

export const readExpirationTime = () => {
	return useStore().expirationTime.value;
};

export const readExpirationTimeShortList = () => {
	return useStore().expirationShortList.value;
};

export const readExpirationTimeLongList = () => {
	return useStore().expirationLongList.value;
};
