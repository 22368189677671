import { createValidationSchema, rules } from '@legacy-studio/library';
import type { PersonalForm } from '../../types';

export const createPersonalSchema = createValidationSchema<PersonalForm>({
	email: ({ email }) => rules.email(email),
	login: ({ login }) => rules.required(login),
	password: ({ password }) => rules.required(password),
	passwordConfirm: ({ password, passwordConfirm }) =>
		rules.passwordConfirm(password, passwordConfirm),
	agreement: ({ agreement }) => rules.required(agreement),
});
